import { Component } from "react";
import { connect } from "react-redux";

// Bootstrap
import { Col, Container, Row } from "reactstrap";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faGlobeAfrica } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faSoundcloud,
  faBehance,
  faDribbble,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

class About extends Component {
  render() {
    // Import Styling
    require("./About.scss");

    return (
      <div className="about">
        <Container>
          <div className="about-card">
            <Row className="align-items-center">
              <Col md={3}>
                <div className="myPP">
                  <img
                    src={this.props.profile_picture}
                    alt={this.props.fullname}
                  />
                </div>
              </Col>
              <Col md={9}>
                <div className="about-content">
                  <h3>{this.props.fullname}</h3>
                  <span>{this.props.job_title}</span>
                  <p>{this.props.description}</p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="social-links">
            <h3>تقدر توصلّي من هنا</h3>

            <div className="links">
              <Row className="justify-content-center">
                <Col lg={2} md={3} sm={4} xs={6}>
                  <a
                    href={this.props.social.soundcloud}
                    target="_blank"
                    className="soundcloud"
                  >
                    ساوند كلاود <FontAwesomeIcon icon={faSoundcloud} />
                  </a>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <a
                    href={this.props.social.behance}
                    target="_blank"
                    className="behance"
                  >
                    بيهانس <FontAwesomeIcon icon={faBehance} />
                  </a>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <a
                    href={this.props.social.dribbble}
                    target="_blank"
                    className="dribbble"
                  >
                    دريبل <FontAwesomeIcon icon={faDribbble} />
                  </a>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <a
                    href={this.props.social.twitter}
                    target="_blank"
                    className="twitter"
                  >
                    تويتر <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <a
                    href={this.props.social.facebook}
                    target="_blank"
                    className="facebook"
                  >
                    فيسبوك <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <a
                    href={`mailto:${this.props.social.email}`}
                    target="_blank"
                    className="email"
                  >
                    إيميل <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <a
                    href={this.props.social.whatsapp}
                    target="_blank"
                    className="whatsapp"
                  >
                    واتس أب <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </Col>
                <Col lg={2} md={3} sm={4} xs={6}>
                  <a
                    href={this.props.website}
                    target="_blank"
                    className="website"
                  >
                    ويبسايت <FontAwesomeIcon icon={faGlobeAfrica} />
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const socialMedia = JSON.parse(state.infoReducer.info[7].option_value);
  return {
    social: socialMedia,
    profile_picture: state.infoReducer.info[3].option_value,
    website: state.infoReducer.info[4].option_value,
    description: state.infoReducer.info[9].option_value,
    job_title: state.infoReducer.info[10].option_value,
    fullname: state.infoReducer.info[11].option_value,
  };
};

export default connect(mapStateToProps)(About);
