import { Component } from "react";
import { connect } from "react-redux";

// Actions
import { closePlayer } from "../../store/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

class Player extends Component {
  render() {
    // Import Styling
    require("./Player.scss");

    return (
      <div className={`player ${this.props.open}`}>
        <div className="close" onClick={() => this.props.closePlayer()}>
          <FontAwesomeIcon icon={faTimes} />
        </div>

        {this.props.open === "opened" && (
          <>
            <div dangerouslySetInnerHTML={{ __html: this.props.iframe }} />
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closePlayer: () => {
      dispatch(closePlayer());
    },
  };
};

export default connect(null, mapDispatchToProps)(Player);
