import { combineReducers } from "redux";

import articlesReducer from "./articlesReducer";
import infoReducer from "./infoReducer";
import playerReducer from "./playerReducer";
import searchReducer from "./searchReducer";

const rootReducer = combineReducers({
  articlesReducer,
  infoReducer,
  playerReducer,
  searchReducer,
});

export default rootReducer;
