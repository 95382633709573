import {
  CLOSE_PLAYER,
  CLOSE_SEARCH,
  FETCH_ARTICLES,
  SINGLE_POST,
  FETCH_INFO,
  OPEN_PLAYER,
  SEARCH,
} from "./types";

// Articles
export const fetchArticles = (payload) => {
  return {
    type: FETCH_ARTICLES,
    data: payload,
  };
};

export const singleArticle = (payload) => {
  return {
    type: SINGLE_POST,
    data: payload,
  };
};

// Info
export const fetchInfo = (payload) => {
  return {
    type: FETCH_INFO,
    data: payload,
  };
};

// Player
export const openPlayer = (payload) => {
  return {
    type: OPEN_PLAYER,
    data: payload,
  };
};

export const closePlayer = (payload) => {
  return {
    type: CLOSE_PLAYER,
    data: payload,
  };
};

// Search
export const search = (payload) => {
  return {
    type: SEARCH,
    data: payload,
  };
};

export const closeSearch = (payload) => {
  return {
    type: CLOSE_SEARCH,
    data: payload,
  };
};
