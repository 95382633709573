import { CLOSE_PLAYER, OPEN_PLAYER } from "../types";

const initialState = {
  openPlayer: "",
  currentTrack: "",
};

const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_PLAYER:
      return {
        openPlayer: "opened",
        currentTrack: action.data,
      };
    case CLOSE_PLAYER:
      return {
        openPlayer: "",
        currentTrack: "",
      };
    default:
      return state;
  }
};

export default playerReducer;
