import { CLOSE_SEARCH, SEARCH } from "../types";

const initialState = {
  search: false,
  word: "",
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        search: true,
        word: action.data,
      };
    case CLOSE_SEARCH:
      return {
        search: false,
        word: "",
      };
    default:
      return state;
  }
};

export default searchReducer;
