import server from "./server";

const subscribe = async (data) => {
  try {
    const email = await server.post("/newsletter", data);
    return email.data;
  } catch (error) {
    return error.response.data;
  }
};

export { subscribe };
