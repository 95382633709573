import { Component } from "react";
import { connect } from "react-redux";
// Layout Components
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";

// Player Component
import Player from "./Player/Player";

class Layout extends Component {
  render() {
    return (
      <>
        <Header />
        {this.props.children}
        <Player
          open={this.props.player.openPlayer}
          iframe={this.props.player.currentTrack}
        />
        <Footer />
        <NavigationBar />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    player: state.playerReducer,
  };
};

export default connect(mapStateToProps)(Layout);
