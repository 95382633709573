import { months } from "./constants";

export const excerpt = (text, length) => {
  return text?.length > length ? text.substring(0, length) + "..." : text;
};

export const convertDate = (date) => {
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear();

  return day + " " + months[month] + " " + year;
};
