import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Actions
import { openPlayer, singleArticle } from "../../store/actions";

// Helpers
import { convertDate, excerpt } from "../../helpers/functions";

// Bootstrap
import { Col, Container, Row } from "reactstrap";

// React Paginate
import ReactPaginate from "react-paginate";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faArrowLeft,
  faHeadphones,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faSoundcloud } from "@fortawesome/free-brands-svg-icons";

// Assets
import squares from "../../assets/images/squares.svg";
import halfCircle from "../../assets/images/half-circle.svg";
import book from "../../assets/images/book.png";
import offlineThumb from "../../assets/images/offline.png";

// Components
import PostCard from "../../components/PostCard/PostCard";

class Home extends Component {
  state = {
    currentItems: null,
    pageCount: 6,
    itemOffset: 0,
    totalPages: 5,
    firstArticle: this.props.articles[0],
    allArticles: this.props.articles.slice(1),
    articles: this.props.articles,
    appearingArticles: [],
  };

  handlePageClick = (e) => {
    window.scrollTo(0, 0);

    this.setState({
      appearingArticles: this.state.allArticles.slice(
        this.state.itemOffset + e.selected * this.state.pageCount,
        this.state.pageCount + e.selected * this.state.pageCount
      ),
    });
  };

  openPlayer = (e, iframe) => {
    e.preventDefault();
    this.props.openPlayer(iframe);
  };

  componentDidMount() {
    this.setState({
      appearingArticles: this.state.allArticles.slice(
        this.state.itemOffset,
        this.state.pageCount
      ),
      totalPages: Math.ceil(
        this.state.allArticles.length / this.state.pageCount
      ),
    });
  }

  render() {
    // Import Styling
    require("./Home.scss");

    let renderedArticles = "";
    const online = window.navigator.onLine;
    const firstArticleThumb = online
      ? process.env.REACT_APP_BASE_URL + this.state?.firstArticle?.thumbnail
      : offlineThumb;

    if (this.props.search.word) {
      console.log(this.state.articles);
      renderedArticles = this.state.articles
        .filter((article) => article.title.includes(this.props.search.word))
        .map((article) => (
          <Col lg={4} md={6} key={article.ID}>
            <PostCard article={article} />
          </Col>
        ));
    } else {
      renderedArticles = this.state.appearingArticles.map((article) => (
        <Col lg={4} md={6} key={article.ID}>
          <PostCard article={article} />
        </Col>
      ));
    }

    return (
      <div className="home">
        {!this.props.search.search && (
          <Container className="no-padding-mobile">
            <Row className="no-margin-mobile">
              <Col md={12} className="no-padding-mobile">
                <div className="download-book">
                  <div className="text">
                    <h1>
                      تقدر تحمل كتاب مجرد كلام <br /> من هنا PDF
                    </h1>
                    <a
                      href={process.env.REACT_APP_BASE_URL + this.props.pdf}
                      download={true}
                    >
                      تحميل الكتاب
                      <FontAwesomeIcon icon={faFileAlt} />
                    </a>
                  </div>

                  <img src={squares} alt="Squares" className="squares" />
                  <img
                    src={halfCircle}
                    alt="HalfCircle"
                    className="halfCircle"
                  />
                  <img src={book} alt="book" className="book" />
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <Container>
          {!this.props.search.search && (
            <Row>
              {this.state.firstArticle ? (
                <Col lg={9} md={6}>
                  <div className="first-post">
                    <img
                      src={firstArticleThumb}
                      alt={this.state?.firstArticle?.title}
                    />
                    <div className="text">
                      <div className="mobile-wrapper">
                        <img
                          src={firstArticleThumb}
                          alt={this.state?.firstArticle?.title}
                        />
                        <div className="texture">
                          <h3>{this.state?.firstArticle?.title}</h3>
                          <div className="date">
                            <FontAwesomeIcon icon={faClock} />
                            {convertDate(this.state?.firstArticle?.createdAt)}
                          </div>
                        </div>
                      </div>
                      <p>
                        {excerpt(
                          this.state?.firstArticle?.content.replace(
                            /<\/?[^>]+(>|$)/g,
                            ""
                          ),
                          230
                        )}
                      </p>

                      <div className="btns">
                        <Link
                          className="btn read"
                          to={`/posts/${this.state?.firstArticle?.ID}`}
                        >
                          قراءة الموضوع
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </Link>
                        <a
                          className="btn listen"
                          href="#"
                          onClick={(e) =>
                            this.openPlayer(e, this.state?.firstArticle?.iframe)
                          }
                        >
                          سماع الموضوع
                          <FontAwesomeIcon icon={faHeadphones} />
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              ) : (
                ""
              )}
              <Col lg={3} md={6}>
                <div className="listen-sc">
                  <div className="wrapper">
                    <div className="icon">
                      <FontAwesomeIcon icon={faSoundcloud} />
                    </div>
                    <p>تابعنا على ساوند كلاود عشان تسمع أخر تسجيلاتنا</p>
                    <a
                      className="btn listen"
                      href={this.props.soundcloud}
                      target="_blank"
                    >
                      إستمع الينا الأن
                      <FontAwesomeIcon icon={faHeadphones} />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row>{renderedArticles}</Row>
          {!this.props.search.search && (
            <ReactPaginate
              breakLabel="..."
              className="pagination"
              nextLabel=">"
              onPageChange={this.handlePageClick}
              pageRangeDisplayed={5}
              pageCount={this.state.totalPages}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const socialMedia = JSON.parse(state.infoReducer.info[6].option_value);

  return {
    articles: state.articlesReducer.articles,
    pdf: state.infoReducer.info[0].option_value,
    soundcloud: socialMedia.soundcloud,
    search: state.searchReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openPlayer: (iframe) => {
      dispatch(openPlayer(iframe));
    },
    singleArticle: (article) => {
      dispatch(singleArticle(article));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
