import { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Gravatar from "react-gravatar";
import { toast } from "react-toastify";

// Assets
import offlineThumb from "../../assets/images/offline.png";

// Server API
import { getComments, addComment } from "../../api/comments";

// Actions
import { singleArticle } from "../../store/actions";

// Helpers
import { convertDate } from "../../helpers/functions";
import { months } from "../../helpers/constants";

// Bootstrap
import { Col, Container, Row } from "reactstrap";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareAlt,
  faPrint,
  faAngleLeft,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";

class SinglePost extends Component {
  state = {
    id: this.props.match.params.id,
    link: window.location.href,
    openShare: false,

    // Comments
    comments: [],
    name: "",
    email: "",
    comment: "",
    loading: false,
    addLoader: false,
  };

  print = (e) => {
    e.preventDefault();

    window.print();
  };

  shareMethods = (e) => {
    e.preventDefault();

    this.setState({ openShare: !this.state.openShare });
  };

  goBack = () => {
    const { history } = this.props;
    history.push("/");
  };

  getSingleArticle = () => {
    const singleArticle = this.props.articles.find(
      (article) => parseInt(article.ID) === parseInt(this.state.id)
    );

    this.props.singleArticle(singleArticle);
  };

  getComments = async () => {
    this.setState({
      loading: true,
    });

    const comments = await getComments(this.state.id);
    this.setState({
      comments: comments.data,
      loading: false,
    });
  };

  addComment = async (e) => {
    e.preventDefault();

    this.setState({
      addLoader: true,
    });

    if (!this.state.name || !this.state.email || !this.state.comment) {
      toast.warn("كل الحقول مطلوبة");
      return this.setState({
        addLoader: false,
      });
    }

    const sendComment = await addComment({
      name: this.state.name,
      comment: this.state.comment,
      email: this.state.email,
      blogID: this.state.id,
    });

    if (!sendComment.success) {
      toast.error("حدث خطأ، برجاء المحاولة مرة اخرى");
      return this.setState({
        addLoader: false,
      });
    }

    await this.getComments();

    toast.success("تم إضافة التعليق بنجاح");

    this.setState({
      name: "",
      email: "",
      comment: "",
      addLoader: false,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSingleArticle();
    this.getComments();
  }

  render() {
    // Import Styling
    require("./SinglePost.scss");

    const online = window.navigator.onLine;

    const { article } = this.props;
    const articleDate = new Date(article.createdAt);
    const articleDateDay = articleDate.getDate();
    const articleDateMonth = months[articleDate.getMonth()];
    const articleThumb = online
      ? process.env.REACT_APP_BASE_URL + article.thumbnail
      : offlineThumb;

    const parentComments = this.state?.comments?.filter(
      (comment) => comment.parentID === null
    );

    const repliesComments = this.state?.comments?.filter(
      (comment) => comment.parentID !== null
    );

    const comments = parentComments?.map((comment) => {
      const replies = repliesComments?.map((reply) => {
        if (reply.parentID === comment.ID) {
          return (
            <li>
              <div className="comment">
                <Gravatar email={reply.email} default="monsterid" size={100} />

                <div className="text">
                  <h3>{reply.name}</h3>
                  <p>{reply.comment}</p>
                </div>
              </div>
            </li>
          );
        }
      });

      return (
        <li>
          <div className="comment">
            <Gravatar email={comment.email} default="monsterid" size={100} />

            <div className="text">
              <h3>{comment.name}</h3>
              <p>{comment.comment}</p>
            </div>
          </div>

          <ol>{replies}</ol>
        </li>
      );
    });

    return (
      <div className="single-post">
        <div className="mobile-post-head">
          <div className="wrapper">
            <div className="date">
              <FontAwesomeIcon icon={faClock} />
              {convertDate(article.createdAt)}
            </div>
            <div className="go-back" onClick={this.goBack}>
              عودة لجميع المقالات
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
          </div>
          <h3>{article.title}</h3>
        </div>
        <Container>
          <div className="top-section">
            <Row>
              <Col md={6} className="no-padding-mobile">
                <div className="thumb">
                  <img src={articleThumb} alt={article.title} />
                </div>
              </Col>
              <Col md={6}>
                <div
                  className="audio"
                  dangerouslySetInnerHTML={{ __html: article.iframe }}
                />
              </Col>
            </Row>
          </div>
          <div className="post-header">
            <div className="info">
              <div className="date">
                <span>{articleDateDay}</span>
                <hr />
                {articleDateMonth}
              </div>
              <h3>{article.title}</h3>
            </div>

            <div className="actions">
              <a href="#" className="share" onClick={this.shareMethods}>
                مشاركة <FontAwesomeIcon icon={faShareAlt} />
              </a>
              {this.state.openShare && (
                <div className="share-methods">
                  <a
                    href={`https://www.facebook.com/sharer.php?u=${this.state.link}`}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${this.state.link}&text=مجرد كلام - ${article.title}`}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${this.state.link}`}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </div>
              )}
              <a href="#" className="print" onClick={this.print}>
                طباعة <FontAwesomeIcon icon={faPrint} />
              </a>
            </div>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />

          <div className="comments">
            <div className="title">التعليقات</div>

            {this.state.loading ? (
              online && (
                <div className="loading-spinner">
                  <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                </div>
              )
            ) : (
              <ul>{comments}</ul>
            )}

            <div className="form">
              <form onSubmit={this.addComment}>
                <Row>
                  <Col md={12}>
                    <textarea
                      name="comment"
                      placeholder="أكتب تعليقك"
                      value={this.state.comment}
                      onChange={(e) =>
                        this.setState({
                          comment: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <input
                      name="name"
                      placeholder="الإسم"
                      value={this.state.name}
                      onChange={(e) =>
                        this.setState({
                          name: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <input
                      name="email"
                      placeholder="الإيميل"
                      value={this.state.email}
                      onChange={(e) =>
                        this.setState({
                          email: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <button type="submit">
                      {this.state.addLoader ? (
                        <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                      ) : (
                        "إرسال التعليق"
                      )}
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    articles: state.articlesReducer.articles,
    article: state.articlesReducer.singleArticle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    singleArticle: (article) => {
      dispatch(singleArticle(article));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SinglePost));
