// Articles
export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const SINGLE_POST = "SINGLE_POST";

// Info
export const FETCH_INFO = "FETCH_INFO";

// Player
export const OPEN_PLAYER = "OPEN_PLAYER";
export const CLOSE_PLAYER = "CLOSE_PLAYER";

// Search
export const SEARCH = "SEARCH";
export const CLOSE_SEARCH = "CLOSE_SEARCH";
