import server from "./server";

const sendEmail = async (data) => {
  try {
    const email = await server.post("/contact", data);
    return email.data;
  } catch (error) {
    return error.response.data;
  }
};

export { sendEmail };
