import { Component } from "react";
import { toast } from "react-toastify";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// Server API
import { sendEmail } from "../../api/email";

// Bootstrap
import { Col, Container, Row } from "reactstrap";

class Contact extends Component {
  state = {
    loading: false,
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  sendMessage = async (e) => {
    e.preventDefault();

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.phone === "" ||
      this.state.message === ""
    )
      return toast.warn("برجاء كتابة البيانات المطلوبة");

    this.setState({
      loading: true,
    });

    const body = `
      <strong>Name:</strong> ${this.state.name} <br>
      <strong>Email:</strong> ${this.state.email} <br>
      <strong>Phone:</strong> ${this.state.phone} <br>
      <strong>Message:</strong> ${this.state.message}
    `;

    sendEmail({
      text: body,
    })
      .then((data) => {
        this.setState({
          loading: false,
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        if (!data.success) {
          return toast.error("حدث خطأ، برجاء المحاولة لاحقا");
        }

        toast.success("تم إرسال الرسالة بنجاح");
      })
      .catch((error) => {
        this.setState({
          loading: false,
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        toast.error("حدث خطأ، برجاء المحاولة لاحقا");
      });
  };

  render() {
    // Import Styling
    require("./Contact.scss");

    return (
      <div className="contact">
        <Container>
          <div className="title">نحب نسمع منك جدا</div>
          <div className="form">
            <form onSubmit={this.sendMessage}>
              <Row>
                <Col md={12}>
                  <textarea
                    name="comment"
                    placeholder="إكتب رسالتك"
                    required
                    value={this.state.message}
                    onChange={(e) =>
                      this.setState({
                        message: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col md={6}>
                  <input
                    name="name"
                    placeholder="الإسم"
                    required
                    value={this.state.name}
                    onChange={(e) =>
                      this.setState({
                        name: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col md={6}>
                  <input
                    name="email"
                    placeholder="الإيميل"
                    required
                    value={this.state.email}
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col md={6}>
                  <input
                    name="phone"
                    placeholder="رقم الموبايل"
                    required
                    value={this.state.phone}
                    onChange={(e) =>
                      this.setState({
                        phone: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col md={6}>
                  <button type="submit">
                    {this.state.loading ? (
                      <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                    ) : (
                      "إرسال"
                    )}
                  </button>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
      </div>
    );
  }
}

export default Contact;
