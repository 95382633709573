import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faHeadphones } from "@fortawesome/free-solid-svg-icons";
import { convertDate, excerpt } from "../../helpers/functions";
import { openPlayer } from "../../store/actions";

// Assets
import offlineThumb from "../../assets/images/offline.png";

class PostCard extends Component {
  openPlayer = (e, iframe) => {
    e.preventDefault();
    this.props.openPlayer(iframe);
  };

  render() {
    // Import Styling
    require("./PostCard.scss");

    const online = window.navigator.onLine;

    const { article } = this.props;
    const articleThumb = online
      ? process.env.REACT_APP_BASE_URL + article.thumbnail
      : offlineThumb;

    return (
      <div className="post-card">
        <div className="text">
          <div className="mobile-wrapper">
            <img src={articleThumb} alt={article.title} />
            <div className="texture">
              <h3>{article.title}</h3>
              <div className="date">
                <FontAwesomeIcon icon={faClock} />
                {convertDate(article.createdAt)}
              </div>
            </div>
          </div>
          <p>{excerpt(article.content.replace(/<\/?[^>]+(>|$)/g, ""), 230)}</p>

          <div className="btns">
            <Link className="btn read" to={`/posts/${article.ID}`}>
              قراءة الموضوع
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            <a
              className="btn listen"
              href="#"
              onClick={(e) => this.openPlayer(e, article.iframe)}
            >
              سماع الموضوع
              <FontAwesomeIcon icon={faHeadphones} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPlayer: (iframe) => {
      dispatch(openPlayer(iframe));
    },
  };
};

export default connect(null, mapDispatchToProps)(PostCard);
