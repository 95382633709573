import { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Server API
import { subscribe } from "../../api/subscribe";

// Bootstrap
import { Col, Container, Row } from "reactstrap";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faEnvelopeOpen, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";

class Footer extends Component {
  state = {
    loading: false,
    email: "",
  };

  subscribe = async (e) => {
    e.preventDefault();
    if (this.state.email === "") return toast.warn("برجاء كتابة الإيميل");

    this.setState({
      loading: true,
    });

    subscribe({
      email: this.state.email,
    })
      .then((data) => {
        this.setState({
          loading: false,
          email: "",
        });

        toast.success("تم الإشتراك بنجاح");
      })
      .catch((error) => {
        this.setState({
          loading: false,
          email: "",
        });

        toast.error("حدث خطأ، برجاء المحاولة لاحقا");
      });
  };

  render() {
    // Import Styling
    require("./Footer.scss");

    const year = new Date().getFullYear();

    return (
      <footer>
        <Container>
          <Row>
            <Col md={4}>
              <div className="widget about">
                <img src={this.props.logoWhite} alt="مجرد كلام" />
                <p>
                  هو مش كتاب أوي، هي تجميعة خواطر ومواقف، هنا انا بوصف أحاسيس
                  كانت ناتجة من مواقف كتير مريت بيها وأغلبنا بيمر بيها كل يوم،
                  حاولت أصيغها بناءا على كل اللي قرأته واتعلمته في الصحة النفسية
                  على شوية قناعات ومبادئ وحبة وجهات نظر تحتمل الصواب والخطأ
                  أكيد.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="widget newsletter">
                <h3>إشترك في قائمتنا البريدية</h3>
                <form onSubmit={this.subscribe}>
                  <input
                    type="email"
                    value={this.state.email}
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                      })
                    }
                    placeholder="أكتب بريدك الإلكتروني"
                  />
                  <button>
                    {this.state.loading ? (
                      <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                    ) : (
                      <FontAwesomeIcon icon={faEnvelope} />
                    )}
                  </button>
                </form>
                <span>يتم كتابة مقال بشكل أسبوعي</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="widget contacts">
                <h3>تابعنا وتواصل معنا</h3>
                <ul className="social">
                  <li>
                    <a href={this.props.facebook} target="_blank">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a href={this.props.twitter} target="_blank">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                </ul>
                <ol>
                  <li>
                    <FontAwesomeIcon icon={faPhone} />
                    <a href={`tel:${this.props.phone}`}>{this.props.phone}</a>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faEnvelopeOpen} />
                    <a href={`mailto:${this.props.email}`}>
                      {this.props.email}
                    </a>
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
          <div className="copyrights">حقوق النشر محفوظة | مجرد كلام {year}</div>
        </Container>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  const socialMedia = JSON.parse(state.infoReducer.info[6].option_value);

  return {
    facebook: socialMedia.facebook,
    twitter: socialMedia.twitter,
    email: state.infoReducer.info[8].option_value,
    phone: state.infoReducer.info[5].option_value,
    logoWhite: state.infoReducer.info[1].option_value,
  };
};

export default connect(mapStateToProps)(Footer);
