import server from "./server";

const getArticles = async () => {
  try {
    const articles = await server.get("/blog");
    return articles.data;
  } catch (error) {
    return error.response.data;
  }
};

const getArticle = async (id) => {
  try {
    const article = await server.get(`/blog/${id}`);
    return article.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getArticles, getArticle };
