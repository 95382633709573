import { FETCH_ARTICLES, SINGLE_POST } from "../types";

const initialState = {
  articles: [],
  singleArticle: {},
};

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES:
      return {
        ...state,
        articles: action.data,
      };
    case SINGLE_POST:
      return {
        ...state,
        singleArticle: action.data,
      };
    default:
      return state;
  }
};

export default articlesReducer;
