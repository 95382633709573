import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

// APIs
import { getArticles } from "./api/blog";
import { getInfo } from "./api/info";

// Actions
import { fetchArticles, fetchInfo } from "./store/actions";

// Loader
import Loader from "./components/Loader/Loader";

// Layout
import Layout from "./components/Layout";

// Pages
import Home from "./pages/Home/Home";
import SinglePost from "./pages/SinglePost/SinglePost";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";

const App = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const online = window.navigator.onLine;

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const fetchData = async () => {
    if (online) {
      const articles = await getArticles();
      articles.data = articles.data.sort((a, b) => parseInt(b.ID) - parseInt(a.ID));
      const info = await getInfo();
      info.data = info.data.sort((a, b) => parseInt(b.ID) - parseInt(a.ID));

      await dispatch(fetchArticles(articles.data));
      await dispatch(fetchInfo(info.data));

      await toDataURL(
        process.env.REACT_APP_BASE_URL + info.data[1].option_value
      ).then((dataUrl) => {
        info.data[1].option_value = dataUrl;
      });

      await toDataURL(
        process.env.REACT_APP_BASE_URL + info.data[2].option_value
      ).then((dataUrl) => {
        info.data[2].option_value = dataUrl;
      });

      await toDataURL(
        process.env.REACT_APP_BASE_URL + info.data[3].option_value
      ).then((dataUrl) => {
        info.data[3].option_value = dataUrl;
      });

      setTimeout(() => {
        window.localStorage.setItem("articles", JSON.stringify(articles.data));
        window.localStorage.setItem("info", JSON.stringify(info.data));
      }, 1000);
    } else {
      const localArticles = JSON.parse(window.localStorage.getItem("articles"));
      const localInfo = JSON.parse(window.localStorage.getItem("info"));

      await dispatch(fetchArticles(localArticles));
      await dispatch(fetchInfo(localInfo));
    }
  };
  useEffect(() => {
    // Calling APIs
    fetchData().then(() => {
      setLoading(false);
    });

    const isDarkMode = localStorage.getItem("dark-mode");

    if (isDarkMode === "true")
      document.documentElement.classList.add("dark-mode");
  }, []);
  return (
    <div className="mogarrad-kalam">
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <Layout>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/posts/:id" component={SinglePost} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
            </Switch>
          </Layout>
        </Router>
      )}
    </div>
  );
};

export default App;
