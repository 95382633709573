import { Component } from "react";
import { NavLink } from "react-router-dom";

// Bootstrap
import { Col, Row } from "reactstrap";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUser, faEnvelope } from "@fortawesome/free-solid-svg-icons";

class NavigationBar extends Component {
  render() {
    // Import Styling
    require("./NavigationBar.scss");

    return (
      <div className="navigation-bar">
        <Row className="mx-0 no-gutters">
          <Col xs={4}>
            <NavLink to="/contact">
              <FontAwesomeIcon icon={faEnvelope} />
              <span>تواصل معنا</span>
            </NavLink>
          </Col>
          <Col xs={4}>
            <NavLink to="/about">
              <FontAwesomeIcon icon={faUser} />
              <span>عن الكاتب</span>
            </NavLink>
          </Col>
          <Col xs={4}>
            <NavLink to="/" exact>
              <FontAwesomeIcon icon={faHome} />
              <span>الرئيسية</span>
            </NavLink>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NavigationBar;
