import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// Toast
import { toast } from "react-toastify";

// Actions
import { closeSearch, search } from "../../store/actions";

// Bootstrap
import { Col, Container, Row, List, ListInlineItem } from "reactstrap";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faMoon,
  faSun,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

class Header extends Component {
  state = {
    darkMode: localStorage.getItem("dark-mode") === "true",
    openSearch: false,
    keyword: "",
    clearSearch: false,
  };

  toggleMode = () => {
    this.setState(
      {
        darkMode: !this.state.darkMode,
      },
      () => {
        localStorage.setItem("dark-mode", this.state.darkMode);
        if (!this.state.darkMode)
          return document.documentElement.classList.remove("dark-mode");
        document.documentElement.classList.add("dark-mode");
      }
    );
  };

  toggleSearch = () => {
    this.setState({
      openSearch: !this.state.openSearch,
    });
  };

  search = (e, keyword) => {
    e.preventDefault();

    if (keyword === "") return toast.warn("برجاء كتابة كلمة بحث");

    this.setState({
      clearSearch: true,
    });

    this.props.search(keyword);
  };

  closeSearch = (e) => {
    e.preventDefault();

    this.setState({
      keyword: "",
      clearSearch: false,
    });

    this.props.closeSearch();
  };

  render() {
    // Import Styling
    require("./Header.scss");

    return (
      <div className="top-bar">
        <Container>
          <Row className="align-items-center">
            <Col md={3} xs={6} sm={12} className="logo-col">
              <Link to="/" className="logo">
                {this.state.darkMode ? (
                  <img src={this.props.logoWhite} alt="مجرد كلام" />
                ) : (
                  <img src={this.props.logo} alt="مجرد كلام" />
                )}
              </Link>
            </Col>
            <Col md={5} xs={1} sm={12} className="search-col">
              <form
                className="search"
                onSubmit={(e) => this.search(e, this.state.keyword)}
              >
                <input
                  type="text"
                  placeholder="بحث عن موضوع"
                  value={this.state.keyword}
                  onChange={(e) => this.setState({ keyword: e.target.value })}
                />
                {!this.state.clearSearch ? (
                  <button type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                ) : (
                  <button type="button" onClick={this.closeSearch}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
              </form>
            </Col>
            <Col
              md={4}
              xs={5}
              sm={12}
              className="d-flex align-items-center justify-content-end justify-content-sm-center menu-col"
            >
              <div className="mobile-search">
                <div className="open-search" onClick={this.toggleSearch}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <form
                  className={`search ${this.state.openSearch && "open"}`}
                  onSubmit={(e) => this.search(e, this.state.keyword)}
                >
                  <input
                    type="text"
                    placeholder="بحث عن موضوع"
                    value={this.state.keyword}
                    onChange={(e) => this.setState({ keyword: e.target.value })}
                  />
                  {!this.state.clearSearch ? (
                    <button type="submit">
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  ) : (
                    <button type="button" onClick={this.closeSearch}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                </form>
              </div>
              <div
                className={`toggle-dark-mode ${
                  this.state.darkMode ? "dark" : ""
                }`}
                onClick={this.toggleMode}
              >
                <div className="toggle-btn">
                  {this.state.darkMode ? (
                    <FontAwesomeIcon icon={faSun} />
                  ) : (
                    <FontAwesomeIcon icon={faMoon} />
                  )}
                </div>
              </div>
              <List type="inline" className="nav-items">
                <ListInlineItem>
                  <Link to="/contact">تواصل معنا</Link>
                </ListInlineItem>
                <ListInlineItem>
                  <Link to="/about">عن الكاتب</Link>
                </ListInlineItem>
                <ListInlineItem>
                  <Link to="/">الرئيسية</Link>
                </ListInlineItem>
              </List>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logo: state.infoReducer.info[2].option_value,
    logoWhite: state.infoReducer.info[1].option_value,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    search: (keyword) => {
      dispatch(search(keyword));
    },
    closeSearch: () => {
      dispatch(closeSearch());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
