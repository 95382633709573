import server from "./server";

const getComments = async (postId) => {
  try {
    const comments = await server.get(`/comments/${postId}`);
    return comments.data;
  } catch (error) {
    return error.response.data;
  }
};

const addComment = async (data) => {
  try {
    const comment = await server.post("/comments", data);
    return comment.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getComments, addComment };
